import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SettingsDocumentDTO, SettingsTaskDTO, SettingsTicketDTO } from '../settings.model';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';

export const SettingsCoreAction = createActionGroup({
  source: 'SettingsCore/API',
  events: {
    'get documents settings': emptyProps(),
    'get documents settings success': props<{ settings: SettingsDocumentDTO }>(),
    'get documents settings failure': NgRxUtils.httpStatusProps(),

    'update documents settings': props<{ settings: SettingsDocumentDTO }>(),
    'update documents settings success': props<{ settings: SettingsDocumentDTO }>(),
    'update documents settings failure': NgRxUtils.httpStatusProps(),

    'get tasks settings': emptyProps(),
    'get tasks settings success': props<{ settings: SettingsTaskDTO }>(),
    'get tasks settings failure': NgRxUtils.httpStatusProps(),

    'update tasks settings': props<{ settings: SettingsTaskDTO }>(),
    'update tasks settings success': props<{ settings: SettingsTaskDTO }>(),
    'update tasks settings failure': NgRxUtils.httpStatusProps(),

    'get ticket settings': emptyProps(),
    'get ticket settings success': props<{ settings: SettingsTicketDTO }>(),
    'get ticket settings failure': NgRxUtils.httpStatusProps(),

    'update ticket settings': props<{ settings: SettingsTicketDTO }>(),
    'update ticket settings success': props<{ settings: SettingsTicketDTO }>(),
    'update ticket settings failure': NgRxUtils.httpStatusProps()
  }
});


export const SettingsDefaultAction = createActionGroup({
  source: 'SettingsCore/DefaultActions',
  events: {
    'clear errors': emptyProps(),
    'clear state': emptyProps()
  }
});
